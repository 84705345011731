import * as am4core from '@amcharts/amcharts4/core'

// Forest theme colors
const radialColors = [
  '#00837A',
  '#7EC2C2',
  '#A8D7DA',
  '#C4E5EA',
  '#E0F3FA',
  '#CACAD3',
  '#AFB6B5',
  '#85908F',
  '#4B6260',
  '#00332F',
].map(color => am4core.color(color))

// Light theme colors
// const radialColors = [
//   '#4389FD',
//   '#7099FC',
//   '#90AAFA',
//   '#ABBBF8',
//   '#DBDEF3',
//   '#CACAD3',
//   '#A5A6B6',
//   '#7F8299',
//   '#5B617D',
//   '#132449',
// ].map(color => am4core.color(color))

export default radialColors
