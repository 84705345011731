import * as am4core from '@amcharts/amcharts4/core'

import { generalConfig } from './generalConfig'

// Forest theme colors
const secondary = {
  main: '#00A3E0',
  dark: '#003346',
  light: '#FAFDFF',
  stroke: '#AFE2F5', // no color spec in figma palette
  contrastText: '#FFFFFF',
}

// Forest theme colors
export const chartSecondaryPalette = [
  am4core.color('#00A3E0'),
  am4core.color('#7DD0EF'),
  am4core.color('#AFE2F5'),
  am4core.color('#C8EBF8'),
  am4core.color('#E5F8FF'),
  am4core.color('#FAFDFF'),
]

// Light theme colors
// const secondary = {
//   main: '#5E01BB',
//   dark: '#45018A',
//   light: '#FAF6FF',
//   stroke: '#EEDBFF', // no color spec in figma palette
//   contrastText: '#FFFFFF',
// }

// export const chartSecondaryPalette = [
//   am4core.color('#5E01BB'),
//   am4core.color('#8F53DB'),
//   am4core.color('#A975E4'),
//   am4core.color('#C197EE'),
//   am4core.color('#D8B9F6'),
//   am4core.color('#EEDBFF'),
// ]

const am4themes_secondary: am4core.ITheme = (target: am4core.BaseObject): void => {
  if (target instanceof am4core.ColorSet) {
    target.list = [...chartSecondaryPalette]
  }

  if (target instanceof am4core.InterfaceColorSet) {
    // Navbar links, Zoom out button, Focus filter.

    target.setFor('primaryButton', am4core.color(secondary.main))
    target.setFor('primaryButtonHover', am4core.color(secondary.dark))
    target.setFor('primaryButtonActive', am4core.color(secondary.main))
    target.setFor('primaryButtonText', am4core.color(secondary.contrastText))
    target.setFor('primaryButtonStroke', am4core.color(secondary.stroke))

    // Scrollbar, Map polygons, Scrollbar, and most of the buttons.

    target.setFor('secondaryButton', am4core.color(secondary.main))
    target.setFor('secondaryButtonHover', am4core.color(secondary.dark))
    target.setFor('secondaryButtonActive', am4core.color(secondary.main))
    target.setFor('secondaryButtonText', am4core.color(secondary.contrastText))
    target.setFor('secondaryButtonStroke', am4core.color(secondary.stroke))

    // Fill of any container, including main chart element, Axis break fill, XY Chart Scrollbar background, etc..
    target.setFor('background', am4core.color(secondary.light))

    // Axis fills, full-width XY cursor, Gauge clock hand, Flow diagram links, Shadow filter, misc map controls, Navigation bar arrow, etc.
    target.setFor('alternativeBackground', am4core.color(secondary.main))

    // Axis tooltip text, Scrollbar grip visual elements.
    target.setFor('alternativeText', am4core.color(secondary.contrastText))
  }
  // Add general settings to the theme
  generalConfig(target)
}

export default am4themes_secondary
