import * as am4core from '@amcharts/amcharts4/core'

import { generalConfig } from './generalConfig'

const primary = {
  main: '#00837A',
  dark: '#00332F',
  light: '#F4FAFF',
  stroke: '#A8D7DA',
  contrastText: '#FFFFFF',
}

export const chartThirdPalette = [
  am4core.color('rgba(0, 143, 134, 100%)'),
  am4core.color('rgba(101, 184, 227, 100%)'),
  am4core.color('rgba(237, 200, 108, 100%)'),
  am4core.color('rgba(211, 103, 91, 100%)'),
  am4core.color('rgba(205, 114, 175, 100%)'),
  am4core.color('rgba(80, 127, 162, 100%)'),
  am4core.color('rgba(242, 171, 94, 100%)'),
  am4core.color('rgba(98, 107, 112, 100%)'),
  am4core.color('rgba(110, 235, 197, 100%)'),
  am4core.color('rgba(20, 11, 66, 100%)'),
  am4core.color('rgba(150, 70, 137, 100%)'),
  am4core.color('rgba(161, 215, 148, 100%)'),
  am4core.color('rgba(62, 71, 132, 1.0)'),
]

// Light theme colors
// export const chartThirdPalette = [
//   am4core.color('rgba(67, 137, 253, 1.0)'),
//   am4core.color('rgba(105, 56, 239, 1.0)'),
//   am4core.color('rgba(221, 37, 144, 1.0)'),
//   am4core.color('rgba(3, 152, 85, 1.0)'),
//   am4core.color('rgba(217, 45, 32, 1.0)'),
//   am4core.color('rgba(62, 71, 132, 1.0)'),
//   am4core.color('rgba(220, 104, 3, 1.0)'),
//   am4core.color('rgba(71, 84, 103, 1.0)'),
//   am4core.color('rgba(68, 76, 231, 1.0)'),
//   am4core.color('rgba(127, 86, 217, 1.0)'),
//   am4core.color('rgba(227, 27, 84, 1.0)'),
//   am4core.color('rgba(0, 134, 201, 1.0)'),
//   am4core.color('rgba(236, 74, 10, 1.0)'),
// ]

const am4themes_main: am4core.ITheme = (target: am4core.BaseObject): void => {
  if (target instanceof am4core.ColorSet) {
    target.list = [...chartThirdPalette]
  }

  if (target instanceof am4core.InterfaceColorSet) {
    // Navbar links, Zoom out button, Focus filter.

    target.setFor('primaryButton', am4core.color(primary.main))
    target.setFor('primaryButtonHover', am4core.color(primary.dark))
    // target.setFor('primaryButtonDown', am4core.color('primary.main'));
    target.setFor('primaryButtonActive', am4core.color(primary.main))
    target.setFor('primaryButtonText', am4core.color(primary.contrastText))
    target.setFor('primaryButtonStroke', am4core.color(primary.stroke))

    // Scrollbar, Map polygons, Scrollbar, and most of the buttons.

    target.setFor('secondaryButton', am4core.color(primary.main))
    target.setFor('secondaryButtonHover', am4core.color(primary.dark))
    // target.setFor('secondaryButtonDown', am4core.color('main.main'));
    target.setFor('secondaryButtonActive', am4core.color(primary.main))
    target.setFor('secondaryButtonText', am4core.color(primary.contrastText))
    target.setFor('secondaryButtonStroke', am4core.color(primary.stroke))

    // Fill of any container, including main chart element, Axis break fill, XY Chart Scrollbar background, etc..
    target.setFor('background', am4core.color(primary.light))

    // Axis fills, full-width XY cursor, Gauge clock hand, Flow diagram links, Shadow filter, misc map controls, Navigation bar arrow, etc.
    target.setFor('alternativeBackground', am4core.color(primary.main))

    // Axis tooltip text, Scrollbar grip visual elements.
    target.setFor('alternativeText', am4core.color(primary.contrastText))

    // Add general settings to the theme
    generalConfig(target)
  }
}

export default am4themes_main
