import * as am4core from '@amcharts/amcharts4/core'

import { generalConfig } from './generalConfig'

// TODO: add colors to DV pallet for each theme
const primary = {
  main: '#00837A',
  dark: '#00332F',
  light: '#F4FAFF',
  stroke: '#A8D7DA',
  contrastText: '#FFFFFF',
}

// Forest theme colors
export const chartPrimaryPalette = [
  am4core.color('#00837A'),
  am4core.color('#7EC2C2'),
  am4core.color('#A8D7DA'),
  am4core.color('#C4E5EA'),
  am4core.color('#E0F3FA'),
  am4core.color('#F5FCFF'),
]

// Light theme colors
// const primary = {
//   main: '#4389FD',
//   dark: '#1A6DFB',
//   light: '#F4FAFF',
//   stroke: '#A1C4FE', // no color spec in figma palette
//   contrastText: '#FFFFFF',
// }
//
// export const chartPrimaryPalette = [
//   am4core.color('#0F6CCA'),
//   am4core.color('#1180EE'),
//   am4core.color('#4CA5FE'),
//   am4core.color('#99CCFF'),
//   am4core.color('#DBEDFF'),
//   am4core.color('#F5FAFF'),
// ]

const am4themes_primary: am4core.ITheme = (target: am4core.BaseObject): void => {
  if (target instanceof am4core.ColorSet) {
    target.list = [...chartPrimaryPalette]
  }

  if (target instanceof am4core.InterfaceColorSet) {
    // Navbar links, Zoom out button, Focus filter.

    target.setFor('primaryButton', am4core.color(primary.main))
    target.setFor('primaryButtonHover', am4core.color(primary.dark))
    // target.setFor('primaryButtonDown', am4core.color('primary.main'));
    target.setFor('primaryButtonActive', am4core.color(primary.main))
    target.setFor('primaryButtonText', am4core.color(primary.contrastText))
    target.setFor('primaryButtonStroke', am4core.color(primary.stroke))

    // Scrollbar, Map polygons, Scrollbar, and most of the buttons.

    target.setFor('secondaryButton', am4core.color(primary.main))
    target.setFor('secondaryButtonHover', am4core.color(primary.dark))
    // target.setFor('secondaryButtonDown', am4core.color('primary.main'));
    target.setFor('secondaryButtonActive', am4core.color(primary.main))
    target.setFor('secondaryButtonText', am4core.color(primary.contrastText))
    target.setFor('secondaryButtonStroke', am4core.color(primary.stroke))

    // Fill of any container, including main chart element, Axis break fill, XY Chart Scrollbar background, etc..
    target.setFor('background', am4core.color(primary.light))

    // Axis fills, full-width XY cursor, Gauge clock hand, Flow diagram links, Shadow filter, misc map controls, Navigation bar arrow, etc.
    target.setFor('alternativeBackground', am4core.color(primary.main))

    // Axis tooltip text, Scrollbar grip visual elements.
    target.setFor('alternativeText', am4core.color(primary.contrastText))
  }
  // Add general settings to the theme
  generalConfig(target)
}

export default am4themes_primary
