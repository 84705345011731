import * as am4charts from '@amcharts/amcharts4/charts'
import * as am4core from '@amcharts/amcharts4/core'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'

am4core.useTheme(am4themes_animated)

const rounded = 25

const generalColors = {
  initial: {
    background: '#EBEBEB',
    disabledBackground: '#E0E0E0',
    disabledText: '#BDBDBD',
    textPrimary: '#212121',
    textSecondary: '#757575',
  },

  general: {
    chartDivider: '#757575',
    dividerColor: '#EEEEEE',
  },

  success: {
    main: '#5BB78E',
  },

  error: {
    main: '#DC5E54',
  },
}

const generalConfig: am4core.ITheme = (target: am4core.BaseObject) => {
  if (target instanceof am4core.InterfaceColorSet) {
    // Axis (axis line, grid, breaks, ticks), XY cursor, Map lines.
    target.setFor('grid', am4core.color(generalColors.general.chartDivider))
    // target.setFor('stroke', am4core.color(general.dividerColor));
    // Text color (useful for changing text color for dark themes).
    target.setFor('text', am4core.color(generalColors.initial.textPrimary))
    // Disabled Legend items, toggled off Chord and Sankey nodes.
    target.setFor('disabledBackground', am4core.color(generalColors.initial.disabledBackground))

    // Positive Candlesticks.
    target.setFor('positive', am4core.color(generalColors.success.main))

    // Negative Candlesticks.
    target.setFor('negative', am4core.color(generalColors.error.main))
  }
  if (target instanceof am4charts.Series) {
    // console.log(`INSTANCE OF -----${target.className}------`, target);
    // target.stroke = 0;
  }

  if (target instanceof am4charts.ColumnSeries) {
    // target.columns.template.adapter.add('width', 60);
    // console.log(`INSTANCE OF -----${target.className}------`, target);
    // && target.className === 'ColumnSeries'
    // console.log(`INSTANCE OF -----${target.className}------`, target.columns.template.width);

    const cornerRadiusTop = (radius: number | undefined, item: am4core.RoundedRectangle) => {
      const { dataItem } = item
      // @ts-ignore stacked prop provided via props
      const isStacked = dataItem.component.stacked
      let lastInSeries

      target.chart.series.each((series) => {
        const isLastInSeries = (dataItem?.dataContext as Record<string, unknown>)[series.dataFields.valueY!]
        const isVisible = !series.isHidden && !series.isHiding

        if (isLastInSeries && isVisible && series.stacked) {
          lastInSeries = series
        }
      })

      if (!isStacked || dataItem?.component === lastInSeries) {
        return rounded
      }

      return radius
    }

    const cornerRadiusBottom = (radius: number | undefined, item: am4core.RoundedRectangle) => {
      const { dataItem } = item
      // @ts-ignore stacked prop provided via props
      const isStacked = dataItem.component.stacked
      let firstInSeries = target.chart.series.values[0]

      target.chart.series.each((series) => {
        const isLastInSeries = (dataItem?.dataContext as Record<string, unknown>)[series.dataFields.valueY!]
        const isHiddenFirstSeries = firstInSeries?.isHidden

        if (isLastInSeries && isHiddenFirstSeries && series.stacked) {
          firstInSeries = series
        }
      })

      if (!isStacked || dataItem?.component === firstInSeries) {
        return rounded
      }

      return radius
    }

    target.columns.template.column.adapter.add('cornerRadiusTopLeft', cornerRadiusTop)
    target.columns.template.column.adapter.add('cornerRadiusTopRight', cornerRadiusTop)
    target.columns.template.column.adapter.add('cornerRadiusBottomLeft', cornerRadiusBottom)
    target.columns.template.column.adapter.add('cornerRadiusBottomRight', cornerRadiusBottom)
    target.columns.template.maxWidth = 15
    // target.columns.template.cellStartLocation = 0.3;
    // target.columns.template.cellEndLocation = 0.7;
    // target.minGridDistance = 15;
    // target.columns.template.maxGridDistance = 20;
  }

  if (target instanceof am4charts.PieSeries) {
    // console.log(`INSTANCE OF -----${target.className}------`, target.grid);
    target.slices.template.stroke = am4core.color('#fff')
    // remove default states for pie section
    target.slices.template.states.getKey('hover')!.properties.scale = 1
    target.slices.template.states.getKey('active')!.properties.shiftRadius = 0
  }

  if (target instanceof am4charts.LineSeries) {
    // console.log(`INSTANCE OF -----${target.className}------`, target.grid);
    target.strokeWidth = 2
  }
  if (target instanceof am4charts.AxisRendererY) {
    // ====== valueAxis ======
    // SET GRID STROKE
    // console.log(`INSTANCE OF -----${target.className}------`, target.grid);
    target.grid.template.disabled = true
    target.grid.template.strokeDasharray = '7'
    target.grid.template.strokeWidth = 0.7
  }

  if (target instanceof am4charts.AxisRendererX) {
    // ====== categoryAxis ======
    // console.log(`INSTANCE OF -----${target.className}------`, target);

    // disable grid Y
    target.fontSize = 12
    target.grid.template.disabled = true
    target.grid.template.strokeDasharray = '7'
    target.grid.template.strokeWidth = 0.7

    target.cellStartLocation = 0.3
    target.cellEndLocation = 0.7
    // target.minGridDistance = 20;
    target.minLabelPosition = 0
  }
  if (target instanceof am4charts.AxisLabel) {
    target.fontSize = 12
    // target.minLabelPosition = 0;
    // target.maxLabelPosition = 1;
  }
}

export { generalConfig, generalColors }
