import * as am4core from '@amcharts/amcharts4/core'

import { generalConfig } from './generalConfig'

const primary = {
  main: '#00837A',
  dark: '#00332F',
  light: '#F4FAFF',
  stroke: '#A8D7DA',
  contrastText: '#FFFFFF',
}

// Rainbow theme colors
export const chartRainbowPalette = [
  am4core.color('#008F86'),
  am4core.color('#65B8E3'),
  am4core.color('#EDC86C'),
  am4core.color('#D3675B'),
  am4core.color('#CD72AF'),
  am4core.color('#507FA2'),
  am4core.color('#F2AB5E'),
  am4core.color('#626B70'),
  am4core.color('#6EEBC5'),
  am4core.color('#140B42'),
  am4core.color('#964689'),
  am4core.color('#A1D794'),
  am4core.color('#3E4784'),
  am4core.color('#FD7944'), // missing color in figma palette
  am4core.color('#E84135'), // missing color in figma palette
  am4core.color('#36C7E7'), // missing color in figma palette
  am4core.color('#5E01BB'), // missing color in figma palette
  am4core.color('#E7364E'), // missing color in figma palette
  am4core.color('#4389FD'), // missing color in figma palette
  am4core.color('#C60077'), // missing color in figma palette
]

// Light theme colors
// export const chartRainbowPalette = [
//   am4core.color('#4389FD'),
//   am4core.color('#5E01BB'),
//   am4core.color('#FDD444'),
//   am4core.color('#36E7D0'),
//   am4core.color('#E84135'),
//   am4core.color('#FDB644'),
//   am4core.color('#FD7944'),
//   am4core.color('#CDFD44'),
//   am4core.color('#4249FC'),
//   am4core.color('#D64F3F'),
//   am4core.color('#FDF444'),
//   am4core.color('#CE35E7'),
//   am4core.color('#5A42FC'),
//   am4core.color('#5EFD44'),
//   am4core.color('#E835AB'),
//   am4core.color('#36C7E7'),
//   am4core.color('#7F03FC'),
//   am4core.color('#E7364E'),
//   am4core.color('#AC03FC'),
//   am4core.color('#C60077'),
// ]

const am4themes_rainbow: am4core.ITheme = (target: am4core.BaseObject): void => {
  if (target instanceof am4core.ColorSet) {
    target.list = [...chartRainbowPalette]
  }

  if (target instanceof am4core.InterfaceColorSet) {
    // Navbar links, Zoom out button, Focus filter.

    target.setFor('primaryButton', am4core.color(primary.main))
    target.setFor('primaryButtonHover', am4core.color(primary.dark))
    // target.setFor('primaryButtonDown', am4core.color('primary.main'));
    target.setFor('primaryButtonActive', am4core.color(primary.main))
    target.setFor('primaryButtonText', am4core.color(primary.contrastText))
    target.setFor('primaryButtonStroke', am4core.color(primary.stroke))

    // Scrollbar, Map polygons, Scrollbar, and most of the buttons.

    target.setFor('secondaryButton', am4core.color(primary.main))
    target.setFor('secondaryButtonHover', am4core.color(primary.dark))
    // target.setFor('secondaryButtonDown', am4core.color('primary.main'));
    target.setFor('secondaryButtonActive', am4core.color(primary.main))
    target.setFor('secondaryButtonText', am4core.color(primary.contrastText))
    target.setFor('secondaryButtonStroke', am4core.color(primary.stroke))

    // Fill of any container, including main chart element, Axis break fill, XY Chart Scrollbar background, etc..
    target.setFor('background', am4core.color(primary.light))

    // Axis fills, full-width XY cursor, Gauge clock hand, Flow diagram links, Shadow filter, misc map controls, Navigation bar arrow, etc.
    target.setFor('alternativeBackground', am4core.color(primary.main))

    // Axis tooltip text, Scrollbar grip visual elements.
    target.setFor('alternativeText', am4core.color(primary.contrastText))
  }
  // Add general settings to the theme
  generalConfig(target)
}

export default am4themes_rainbow
